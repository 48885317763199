import React from "react"
import styled from "styled-components"

const NavStyles = styled.div`
  .nav-container {
    width: 100%;
    padding: 0.3rem;
    background-color: lightblue;
    align-content: center;
  }

  .item-container {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    padding: 0 2rem;

    .link-container {
      display: flex;
      justify-content: space-around;
    }

    .login {
      justify-self: right;
      align-self: center;
    }
  }
`

const NewNavBar = () => {
  return (
    <NavStyles>
      <div className="nav-container">
        <div className="item-container">
          <div className="logo"></div>
          <div className="link-container">
            <p>Home</p>
            <p>About</p>
            <p>Contact</p>
            <p>Blog</p>
            <i
              style={{ fontSize: "150px", zIndex: "4" }}
              className="fas fa-ad"
            ></i>
          </div>
          <div className="login">
            <button>Login In</button>
          </div>
        </div>
      </div>
    </NavStyles>
  )
}

export default NewNavBar
