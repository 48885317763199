import React from "react"
import NewNavBar from "../components/NewNavBar"

const TestPage = () => {
  return (
    <div>
      <NewNavBar />
    </div>
  )
}

export default TestPage
